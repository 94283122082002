import { useEffect, useState } from "react";

import { ArrowButton, AutocompleteMultipleSelect, ButtonLink, Modal, TextField, Typography, UnauthorizedPage } from "@king-ict/eupisi-ui/components";
import { useInput, useValidation } from "@king-ict/eupisi-ui/hooks";
import { nameof } from "@king-ict/eupisi-ui/utils";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import { editUser, getRoles , getUser } from "../../api/User/UserApi";
import { ContentArea } from "../../components";
import { AuthUser, authUserInitial, CreateUpdateUserValidationScheme, Role } from "../../models/User";
import { userListRoute } from "../../routes/routes";
import styles from "./Users.module.scss";

export function UserEdit() {

	const { t } = useTranslation();
	const history = useHistory();

	const { id } = useParams<{ id: string }>();

  const { handleChange } = useInput();

  const[roles, setRoles] = useState<Role[]>([]);

  const [user, setUser] = useState<AuthUser>(authUserInitial);

  const [openEditUserConfirmModal, setOpenEditUserConfirmModal] = useState(false);

  const [isDirty, setIsDirty] = useState(false);

  //const type = id ? "update" : "create";

  const [errors, doValidations] = useValidation({
    data: user,
    validationSchema: CreateUpdateUserValidationScheme,
  });
  
  useEffect(() => {
		(async () => {
			setUser(await getUser(+id));      
      setTimeout(() => {
        setIsDirty(false);
      }, 0);
		})();
	 }, [id]);

   useEffect(() => {
		(async () => {
			setRoles(await getRoles());
		})();
	 }, []);

   useEffect(() => {
		setIsDirty(true);
	 }, [user]);
   
   const handleRoleChange = (e: any) => {
		const newRoleList = e.target.value as Role[];
		const newUser: AuthUser = {
			...user,
			roles: newRoleList
		};
		setUser(newUser);
	  };

     const onEditClick = async () => {
      const isValid = doValidations();
      if (isValid) {
        setUser(user);
        handleToggleOpenEditUserConfirmModal();
      }
    };
    
    const handleEdit = async () => {
      await editUser(user)
      .then(handleToggleOpenEditUserConfirmModal)
      .then(goToUserList);
      };
    
     
    const goToUserList = () => {
      history.push(userListRoute);
      };

   const handleToggleOpenEditUserConfirmModal = () => {
    setOpenEditUserConfirmModal(
      (openEditNotificationConfirmModal) => !openEditNotificationConfirmModal
    );
  };

  return (<><ContentArea>
    {user ? (
      <><Row>
				<Col md={12}>
				<ArrowButton
				backwards
				ariaLabel={t("accessibility.backButton")}
				onClick={() => history.push(userListRoute)}
			/>
			<ButtonLink onClick={() => history.push(userListRoute)}>
      {t("user.backToList")}
			</ButtonLink>
				</Col>
		</Row>
    <Row>
        <Col md={12}>
          <Typography
            variant="XLargeBold"
            component={"h1"}
            className={styles.title}
          >
            {t("user.edit")}
          </Typography>
        </Col>
      </Row><Row>
          <Col md={6}>
            <TextField
              label={t("user.firstName")}
              name={nameof<typeof user>("firstName")}
              onChange={(e) => handleChange(e, setUser)}
              value={user?.firstName || ""}
              required={true}
              errorText={errors?.firstName || ""} />
          </Col>

          <Col md={6}>
            <TextField
              label={t("user.lastName")}
              name={nameof<typeof user>("lastName")}
              onChange={(e) => handleChange(e, setUser)}
              value={user?.lastName || ""}
              required={true}
              errorText={errors?.lastName || ""} />
          </Col>
        </Row><Row>
          <Col md={6}>
            <AutocompleteMultipleSelect<Role>
              label={t("user.roles")}
              name={nameof<typeof user>("roles")}
              value={user.roles || []}
              valueProp="id"
              textProp="name"
              onChange={handleRoleChange}
              selectOptions={roles}
              maximumSelectedItems={roles.length}
              minimumInputLength={0}
              required={true}
              errorText={errors?.roles?.toString()} />
          </Col>

          <Col md={6}>
            <TextField
              label={t("user.oib")}
              name={nameof<typeof user>("oib")}
              onChange={(e) => handleChange(e, setUser)}
              value={user?.oib || ""}
              required={true}
              errorText={errors?.oib || ""} />
          </Col>
        </Row><Button 
          className={styles.btnMargin} 
          onClick={onEditClick}
          disabled={!isDirty}
        >
          
          {t("common.save")}
        </Button></>
    ) : 
    <>
    <UnauthorizedPage 
      partial 
      actionText={t("user.backToList")}
      action={goToUserList}
    />
    </>
    }
		
  </ContentArea>
  <Modal
    type={"confirm"}
    open={openEditUserConfirmModal}
    title={t("user.edit")}
    confirmText={t("common.approve")}
    cancelText={t("common.quit")}
    onConfirm={handleEdit}
    onClose={handleToggleOpenEditUserConfirmModal}
  >
      <div>
      {t("user.askToSave")}
      </div>
    </Modal></>
)
}