import { useEffect, useState } from "react";

import { Button, Checkbox, ExpandablePanel, ExpandablePanelBody, ExpandablePanelHeader, Modal, TextEditor, Typography } from "@king-ict/eupisi-ui/components";
import { ReactComponent as AddIcon } from "@king-ict/eupisi-ui/components/Icon/Add.svg";
import { ReactComponent as EditIcon } from "@king-ict/eupisi-ui/components/Icon/Edit.svg";
import { ReactComponent as RemoveIcon } from "@king-ict/eupisi-ui/components/Icon/RemoveIcon.svg";
import { hrDate } from "@king-ict/eupisi-ui/utils";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { deleteNotifications, getModulNotifications } from "../../api/Notification/NotificationApi";
import { ContentArea, FormCard } from "../../components";
import { ModulesAndNotificationsDto, NotificationDto, NotificationModuleDeleteDto } from "../../models/NotificationModules";
import { notificationCreateRoute, notificationEditUrl } from "../../routes/routes";
import styles from "./Notifications.module.scss";

export function Notifications() {
  const { t } = useTranslation();
  const history = useHistory();
  const [moduleAndNotifications, setModuleAndNotifications] = useState<ModulesAndNotificationsDto[]>([]);
  const [deleteNotificationModule, setDeleteNotificationModule] = useState<NotificationModuleDeleteDto>();
  const [openDeleteNotificationModuleModal, setOpenDeleteNotificationModuleModal] = useState(false);
  const [shouldDeleteForAllModules, setShouldDeleteForAllModules] = useState(true);
  const [notificationsFetched, setNotificationsFetched] = useState(false);

useEffect(() => {
  getAllNotificationAndModules();
}, []);


const getAllNotificationAndModules = () => {
  (async () => {
    var result = await getModulNotifications();
    setModuleAndNotifications(result);
    setNotificationsFetched(true);
})();
};

const onCreateClick = () => {
  history.push(notificationCreateRoute);
};

const onEditClick =(id: number) => {
  history.push(notificationEditUrl(id!))
};

const onDeleteClick = async (notificationId: number, moduleId: number) => {
  const notificationModulForDelete: NotificationModuleDeleteDto = {
    notificationId: notificationId,
    moduleId: moduleId,
    forAllModules: false
  };
  setDeleteNotificationModule(notificationModulForDelete);
  handleToggleDeleteNotificationModuleModal();
};

const handleDelete = async () => {
  if (deleteNotificationModule) {
    const newNotificationModuleForDelete: NotificationModuleDeleteDto = {
      ...deleteNotificationModule,
      forAllModules: shouldDeleteForAllModules
    };
    await deleteNotifications(newNotificationModuleForDelete)
    .then(handleToggleDeleteNotificationModuleModal);
    getAllNotificationAndModules();
  }
};

const handleToggleDeleteNotificationModuleModal = () => {
  setOpenDeleteNotificationModuleModal(
    (openDeleteNotificationModuleModal) => !openDeleteNotificationModuleModal
  );
};

const handleTypeChange = () => {
  setShouldDeleteForAllModules(!shouldDeleteForAllModules);
  };

const showModal = () => {
  return <Modal
    type={"confirm"}
    open={openDeleteNotificationModuleModal}
    title={t("notification.delete")}
    confirmText={t("common.approve")}
    cancelText={t("common.quit")}
    onConfirm={handleDelete}
    onClose={handleToggleDeleteNotificationModuleModal}
  >
    <Row>
      <Col md={12}>
        {t("notification.askToDelete")}
      </Col>
    </Row>

    <Row>
      <Col md={12}>
        <Checkbox
        label={t("notification.deleteForAllModules")}
        checked={shouldDeleteForAllModules}
        onChange={handleTypeChange}
        />
      </Col>
    </Row>
</Modal> 
};


const getModules = () => {
  if (notificationsFetched) {
    if (moduleAndNotifications.length > 0) {
      return <ContentArea>
       <Container fluid>
       <Row>
          <Col md={12}>
          <Typography
              variant="XLargeBold"
              component={"h1"}
              className={styles.title}
            >
              {t("notification.list")}
            </Typography>
          </Col>
      </Row>
        {moduleAndNotifications.map((mn) => (
           (mn.notifications.length > 0 && (
          <Row key={"on-" + mn.ordinalNumber}>
            <Col md={12} className={styles.panelsCol}>
             
                <ExpandablePanel clickableHeader className={styles.panel}>
                  <ExpandablePanelHeader clickableHeader className={styles.panelHeader}>
                    {mn.name}
                  </ExpandablePanelHeader>
                  <ExpandablePanelBody>
                    {getNotificationsFromModule(mn.notifications, mn.id)}
                  </ExpandablePanelBody>
                </ExpandablePanel>
                </Col>
                </Row>
           ))
        ))}
          <Button 
            color="primary" 
            onClick={onCreateClick}>
            {t("common.addNew")}
            <AddIcon className={styles.icon}/>
          </Button>
          {showModal()}
       </Container>
      </ContentArea>
  }
    else {
      return <ContentArea>
        <Container fluid>
          <Typography variant="baseBold">
            {t("notification.emptyList")}
          </Typography>
          </Container>
      </ContentArea>
    }
  }
  else {
    return <ContentArea>
    <Container fluid>
      </Container>
  </ContentArea>
  }

};

const getNotificationsFromModule = (notifications: NotificationDto[], moduleId: number) => {
  if (notifications.length > 0) {
    return notifications.map((n, index) => (
      <Row key={"no-" + index}>       
        <Col md={12}>
          <div className={styles.formCardInfo}>
            <FormCard cardTitle={n.title}>
              <Row>
                <Col md={12}>
                  <span className={styles.panelsSubHeader}>{hrDate(n.dateFrom)}</span>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                <TextEditor 
                  initialValue={n.text}
                  handleChange={()=>{}}
                  plaintext={true}
                 />
                  </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Button 
                    className={styles.buttonIcon} 
                    color="primary" 
                    onClick={() => onEditClick(n.id)}>
                      {t("common.edit")}
                      <EditIcon className={styles.icon}/>
                  </Button>
                  <Button 
                    className={styles.buttonIcon} 
                    color="danger" 
                    onClick={() => onDeleteClick(n.id, moduleId)}>
                      {t("common.delete")}
                      <RemoveIcon className={styles.icon}/>                 
                  </Button>
                  
                  </Col>
              </Row>
            </FormCard>
          </div>
        </Col>
        </Row>
    ))
  }

    

  };
  
  return getModules();
}
