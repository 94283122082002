import { Button, Typography } from "@king-ict/eupisi-ui/components";
import { useKeycloak } from "@king-ict/eupisi-ui/hooks";
import { Container } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";


import { ReactComponent as MenuIcon } from "../../assets/images/MenuIcon.svg";
import styles from "./Navigation.module.scss";

export interface NavigationProps {
  onIconClick?: (event: React.MouseEvent<any, MouseEvent>) => void;
}

export function Navigation({ onIconClick }: NavigationProps) {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();

  return (
    <>
      <Navbar className={styles.navbar} expand="sm" >
        <Container className="p-0 m-0" fluid>
          <Navbar.Brand
            className="pb-3"
          >            
              <Button
                boxless={true}
                onClick={onIconClick}
                className="pb-2"
              >
              <MenuIcon />
              </Button>            
            <Typography
              component="span"
              variant="largeBold"
              color="light"
              className="pl-4"
            >
              {t("navigation.title")}
            </Typography>
          </Navbar.Brand>
        </Container>
      </Navbar>
    </>
  );
}