import { CodeBook } from "@king-ict/eupisi-ui/models";
import { api } from "@king-ict/eupisi-ui/utils";

import { ModulesDto } from "../../models/NotificationModules";

const apiUrlPrefix: string = "modules";

export const getModules = async () => {
  return api.get(
      `${apiUrlPrefix}`
  ) as Promise<ModulesDto[]>
}

export const getModulesCodeBook = async () => {
  return api.get(
      `${apiUrlPrefix}/modulesCodeBook`
  ) as Promise<CodeBook[]>
}