import { CodeBook } from "@king-ict/eupisi-ui/models";

import { Role } from "../Shared/Role";
import { User } from "./User";

export interface UserPermission extends CodeBook {
    user: User;
    canImpersonate: boolean;
    rolePermissions?: Role[];
    locations?: number[];
    claimType: ClaimType;
    role: number;
}

export enum ClaimType
{
    Institution = 1,
    County,
    Role,
    Global,
}