import { RoleEnum } from "./RoleEnum";

export interface User {
    id: number;
    oib?: string;
    firstName?: string;
    lastName?: string;
    userRoles: RoleEnum;
    oibValidated: boolean;
    niasMessageId: string;
    niasNavigationToken: string;
    niasSessionId: string;
}


export const userInitial: User = {
    id: 0,
    oib: "",
    firstName: "",
    lastName: "",
    userRoles: 0,
    oibValidated: false,
    niasMessageId: "",
    niasNavigationToken: "",
    niasSessionId: "",
  };