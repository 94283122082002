import { useEffect, useState } from "react";

import { ArrowButton, AutocompleteMultipleSelect, ButtonLink, DatePicker, Modal, TextEditor, TextField, Typography, UnauthorizedPage } from "@king-ict/eupisi-ui/components";
import { useInput, useValidation } from "@king-ict/eupisi-ui/hooks";
import { CodeBook } from "@king-ict/eupisi-ui/models";
import { nameof } from "@king-ict/eupisi-ui/utils";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import { getModulesCodeBook } from "../../api/Modules/ModulesApi";
import { getNotification } from "../../api/Notification/NotificationApi";
import { editNotificationModule } from "../../api/NotificationModule/NotificationModuleApi";
import { ContentArea } from "../../components";
import { CreateNotificationModule, CreateNotificationModuleInitial, CreateUpdateNotificationValidationScheme } from "../../models/NotificationModules";
import { notificationListRoute } from "../../routes/routes";
import styles from "./Notifications.module.scss";

export function NotificationEdit() {

	const { t } = useTranslation();
	const history = useHistory();

	const { id } = useParams<{ id: string }>();

  const { handleChange } = useInput();


	const[modules, setModules] = useState<CodeBook[]>([]);

  const [notification, setNotification] = useState<CreateNotificationModule>(CreateNotificationModuleInitial);

  const [openEditNotificationConfirmModal, setOpenEditNotificationConfirmModal] = useState(false);

  const [isDirty, setIsDirty] = useState(false);

  //const type = id ? "update" : "create";

  const [errors, doValidations] = useValidation({
    data: notification,
    validationSchema: CreateUpdateNotificationValidationScheme,
  });
  
  useEffect(() => {
		(async () => {
			var result = await getNotification(+id)
			setNotification(result);
      setTimeout(() => {
        setIsDirty(false);
      }, 0);
		})();
	 }, [id]);

	useEffect(() => {
		(async () => {
			var result = await getModulesCodeBook();
			setModules(result);
		})();
	 }, []);

   useEffect(() => {
		setIsDirty(true);
	 }, [notification]);
   
	 const handleModulesChange = (e: any) => {
		const newModuleList = e.target.value as CodeBook[];
		const newNotification: CreateNotificationModule = {
			...notification,
			modules: newModuleList
		}
		setNotification(newNotification);
	  };

     const onEditClick = async () => {
      const isValid = doValidations();
      if (isValid) {
        setNotification(notification);
        handleToggleOpenEditNotificationConfirmModal(); 
      }          
    };
    
    const handleEdite = async () => {
      await editNotificationModule(notification)
      .then(handleToggleOpenEditNotificationConfirmModal)
      .then(goToNotificationForModuleList);
      };
    
     
  const goToNotificationForModuleList = () => {
		history.push(notificationListRoute);
	 };

  const handleTextChange = (newText: string) => {
    const newNotification: CreateNotificationModule = {
      ...notification,
      text: newText
    };
    setNotification(newNotification);
  };

   const handleToggleOpenEditNotificationConfirmModal = () => {
    setOpenEditNotificationConfirmModal(
      (openEditNotificationConfirmModal) => !openEditNotificationConfirmModal
    );
  };

  if (!notification.text) {
    return null;
  };

	return (<><ContentArea>
{notification ? (
    <>
      <Row>
				<Col md={12}>
				<ArrowButton
				backwards
				ariaLabel={t("accessibility.backButton")}
				onClick={() => history.push(notificationListRoute)}
			/>
			<ButtonLink onClick={() => history.push(notificationListRoute)}>
        {t("notification.backToList")}
			</ButtonLink>
				</Col>
		</Row>
  
   <Row>
        <Col md={12}>
          <Typography
            variant="XLargeBold"
            component={"h1"}
            className={styles.title}
          >
            {t("notification.edit")}
          </Typography>
        </Col>
      </Row>
      <Row>
          <Col md={6}>
            <AutocompleteMultipleSelect<CodeBook>
              label={t("notification.modules")}
              name={nameof<typeof notification>("modules")}
              value={notification.modules || []}
              valueProp="id"
              textProp="name"
              onChange={handleModulesChange}
              selectOptions={modules}
              maximumSelectedItems={modules.length}
              minimumInputLength={0}
              required={true}
              errorText={errors?.modules?.toString() || ""} />
          </Col>

          <Col md={6}>
            <TextField
              label={t("notification.title")}
              value={notification?.title || ""}
              name={nameof<typeof notification>("title")}
              onChange={(e) => handleChange(e, setNotification)}
              required={true}
              errorText={errors?.title || ""} />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <TextEditor
              label={t("notification.text")}
              initialValue={notification?.text}
              handleChange={handleTextChange}
              required={true}
              errorText={errors?.text || ""}
            />
          </Col>
        </Row><Row>
          <Col md={6}>
            <DatePicker
              value={notification?.dateFrom}
              label={t("notification.dateFrom")}
              name={nameof<typeof notification>("dateFrom")}
              onChange={(e) => handleChange(e, setNotification)}
              showTimePicker={true}
              required={true}
              errorText={errors?.dateFrom || ""}
            />
          </Col>

          <Col md={6}>
            <DatePicker
              value={notification?.dateTo}
              label={t("notification.dateTo")}
              name={nameof<typeof notification>("dateTo")}
              onChange={(e) => handleChange(e, setNotification)}
              showTimePicker={true} />
          </Col>
        </Row>
        <Button 
          className={styles.btnMargin} 
          onClick={onEditClick}
          disabled={!isDirty}
        >
          {t("common.save")}
        </Button></>
  ) : 
  <>
  <UnauthorizedPage 
    partial 
    actionText={t("notification.backToList")}
    action={goToNotificationForModuleList}
  />
  </>
  }
		
  </ContentArea>
  <Modal
    type={"confirm"}
    open={openEditNotificationConfirmModal}
    title={t("notification.edit")}
    confirmText={t("common.approve")}
    cancelText={t("common.quit")}
    onConfirm={handleEdite}
    onClose={handleToggleOpenEditNotificationConfirmModal}
  >
    <div>
      {t("notification.askToSave")}
    </div>
    </Modal></>
    )
}