import { api } from "@king-ict/eupisi-ui/utils";

import { ModulesDto } from "../../models/NotificationModules";

const apiUrlPrefix: string = "modules-admin";

export const getAdminModules = async () => {
  return api.get(
      `${apiUrlPrefix}`
  ) as Promise<ModulesDto[]>
}