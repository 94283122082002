import { TFunction } from "i18next";
import * as yup from "yup";

export interface CreateUpdateUserValidationSchemeProps {
  t: TFunction;
}

export const CreateUpdateUserValidationScheme = ({
  t,
}: CreateUpdateUserValidationSchemeProps) => {
  const requiredMsg = t("validations.required");
  const oibFormat = t("validations.oibFormat");
  const oibFormatValidation = t("validations.oibValidation");
  const requiredAtLeastOne = t("validations.requiredAtLeastOne");

  return yup.object().shape({
    oib: yup
    .string()
    .trim()
    .matches(/^[0-9]+$/, oibFormat)
    .required(requiredMsg)
    .min(11, oibFormat)
    .max(11, oibFormat)
    .test("checkOibValidation", "isRequired", function (value) {
      var length = value?.length;
      var oibValue = value ?? "";

      if (length) {
        var residue = 10;
        for (var i = 0; i < length - 1; i++) {
          var digit = parseInt(oibValue[i]);
          var midResult = (digit + residue) % 10;
          midResult = midResult === 0 ? 10 : midResult;
          residue = (midResult * 2) % 11;
        }

        var lastDigit = parseInt(oibValue[length - 1]);
        var controllCharacter = 11 - residue;
        if (controllCharacter === lastDigit) {
          return true;
        } else if (controllCharacter === 10 && 0 === lastDigit) {
          return true;
        } else {
          return this.createError({
            path: "oib",
            message: oibFormatValidation,
          });
        }
      } else {
        return this.createError({
          path: "oib",
          message: oibFormatValidation,
        });
      }
    }),    
      firstName: yup
        .string()
        .trim()
        .required(requiredMsg)
        .max(50, t("validations.tooLong", {
          numberOfCharacters: 50,
        })),
      lastName: yup
        .string()
        .trim()
        .required(requiredMsg)
        .max(50, t("validations.tooLong", {
          numberOfCharacters: 50,
        })),
      roles: yup
        .array()
        .min(1, requiredAtLeastOne),
  });
};
