import { useEffect, useState } from "react";

import { Button, Modal, Table, TableMetadata, Typography, useTable } from "@king-ict/eupisi-ui/components";
import { ReactComponent as AddIcon } from "@king-ict/eupisi-ui/components/Icon/Add.svg";
import { ReactComponent as EditIcon } from "@king-ict/eupisi-ui/components/Icon/Edit.svg";
import { ReactComponent as RemoveIcon } from "@king-ict/eupisi-ui/components/Icon/RemoveIcon.svg";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { getUsers } from "../../api/User/UserApi";
import { deleteUser as deleteUserApi } from "../../api/User/UserApi";
import { ContentArea,  } from "../../components";
import { AuthUser, RoleEnum } from "../../models/User";
import { userCreateRoute, userEditUrl } from "../../routes/routes";
import styles from "./Users.module.scss";


export function Users() {
const { t } = useTranslation();
const history = useHistory();

const [tableData, setTableData] = useState<AuthUser[]>([]);
const [deleteUser, setDeleteUser] = useState<AuthUser>();
const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);  
const [usersFetched, setUsersFetched] = useState(false);


useEffect(() => {
  getAllUsers();
}, []);

const getAllUsers = () => {
  (async () => {
    var result = await getUsers();
    setTableData(result);
    setUsersFetched(true);
})();
};

const onCreateClick = () => {
  history.push(userCreateRoute);
};

const onEditClick =(id: number) => {
  history.push(userEditUrl(id!))
};

const onDeleteClick = async (userId: number) => {
  const userForDelete = tableData.find(x => x.id === userId);
  if (userForDelete) {
    setDeleteUser(userForDelete);
    handleToggleDeleteUserModal();
  }  
};

const handleDelete = async () => {
  if (deleteUser) {
    await deleteUserApi(deleteUser.id)
    .then(handleToggleDeleteUserModal);
    getAllUsers();
  }
};

const handleToggleDeleteUserModal = () => {
  setOpenDeleteUserModal(
    (openDeleteUserModal) => !openDeleteUserModal
  );
};

const showModal = () => {
  return (
  <Modal
    type={"confirm"}
    open={openDeleteUserModal}
    title={t("user.delete")}
    confirmText={t("common.approve")}
    cancelText={t("common.quit")}
    onConfirm={handleDelete}
    onClose={handleToggleDeleteUserModal}
  >
      <div>
      {t("user.askToDelete")}
      </div>
  </Modal> 
)
};

const setRoleStyle = (roleId: number, roleName: string) => {
  switch(roleId) {
    case RoleEnum.Administrator:
      return <p className={styles.roleAdmin} key={"on-" + roleId}>{roleName}</p>

    case RoleEnum.CAS:
      return <p className={styles.roleCAS} key={"on-" + roleId}>{roleName}</p>
  }
}

const tableMetadata: TableMetadata<AuthUser> = {
  rowKey: "id",
  rowToolTip: () => undefined,
  columns: [
    {
      headerClassName: "col-2",
      columnKey: "oib",
      header: "Oib",
      accessor: "oib",
    },
    {
      headerClassName: "col-2",
      columnKey: "firstName",
      header: "Ime",
      accessor: "firstName",
    },
    {
      headerClassName: "col-2",
      columnKey: "lastName",
      header: "Prezime",
      accessor: "lastName"
    },
    {
      headerClassName: "col-2",
      columnKey: "roles",
      header: "Role",
      accessor: (u: AuthUser) => {
        return (
             (u.roles.map((role, index) => (
              setRoleStyle(role.id, role.name)
          )))
        )
      }
    },
    {
      headerClassName: "col-1",
      columnKey: "edit",
      header: "",
      accessor: (u: AuthUser) => {
        return (
          <Button 
            color="primary" 
            onClick={() => onEditClick(u.id)}>
               {t("common.edit")}
              <EditIcon className={styles.icon}/>
        </Button>
        )
      }
    },

    {
      headerClassName: "col-1",
      columnKey: "delete",
      header: "",
      accessor: (u: AuthUser) => {
        return (
          <Button 
            color="danger" 
            onClick={() => onDeleteClick(u.id)}>
             {t("common.delete")}
              <RemoveIcon className={styles.icon}/>
        </Button>
        )
      }
    },
  ],
};


const [tableProps] = useTable(
  {
    metadata: tableMetadata,
    data: tableData,
    pagination: false,
    paginationPage: undefined,
    paginationPageSize: undefined as any,
    totalItems: 1,
    defaultSort: {}
  }
);

  return (
  <ContentArea>
    <Container fluid>
      {usersFetched ? (
      (tableData && tableData.length > 0 ? (
  <><Row>
      <Col md={12}>
        <Typography
          variant="XLargeBold"
          component={"h1"}
          className={styles.title}
        >
          {t("user.list")}
        </Typography>
      </Col>
    </Row>
    <div><Table {...tableProps} /></div>
    <Button
      color="primary"
      onClick={onCreateClick}>
        {t("common.addNew")}
        <AddIcon className={styles.icon} />
      </Button></>      
) : 
<Typography variant="baseBold">
  {t("user.emptyList")}
</Typography> 
)
    ) : null}
      
    {showModal()}
    </Container>
  </ContentArea>
  );
}