import { useEffect, useState } from "react";

import { ArrowButton, AutocompleteMultipleSelect, ButtonLink, DatePicker, Modal, TextEditor, TextField, Typography } from "@king-ict/eupisi-ui/components";
import { useInput, useValidation } from "@king-ict/eupisi-ui/hooks";
import { CodeBook } from "@king-ict/eupisi-ui/models";
import { nameof } from "@king-ict/eupisi-ui/utils";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { getModulesCodeBook } from "../../api/Modules/ModulesApi";
import { createNotificationModule } from "../../api/NotificationModule/NotificationModuleApi";
import { ContentArea } from "../../components";
import { CreateNotificationModule, CreateNotificationModuleInitial, CreateUpdateNotificationValidationScheme } from "../../models/NotificationModules";
import { notificationListRoute } from "../../routes/routes";
import styles from "./Notifications.module.scss";

export function NotificationCreate() {

	const { t } = useTranslation();
	const history = useHistory();

	const { handleChange } = useInput();

	const[modules, setModules] = useState<CodeBook[]>([]);

	const [notification, setNotification] = useState<CreateNotificationModule>(CreateNotificationModuleInitial);

	const [openCreateNotificationConfirmModal, setOpenCreateNotificationConfirmModal] = useState(false);

	//const type = id ? "update" : "create";

	const [errors, doValidations] = useValidation({
    data: notification,
    validationSchema: CreateUpdateNotificationValidationScheme,
  });

	useEffect(() => {
		(async () => {
			var result = await getModulesCodeBook();
			setModules(result);
		})();
	 }, []);

	 const handleModulesChange = (e: any) => {
		const newModuleList = e.target.value as CodeBook[];
		const newNotification: CreateNotificationModule = {
			...notification,
			modules: newModuleList
		}
		setNotification(newNotification);
	  };

		const onCreateClick = async () => {
			const isValid = doValidations();
			if (isValid) {
				setNotification(notification);
				handleToggleOpenCreateNotificationConfirmModal();
			}
    };

		const handleCreate = async () => {
      await createNotificationModule(notification)
			.then(handleToggleOpenCreateNotificationConfirmModal)
			.then(goToNotificationForModuleList);
      };

	  const goToNotificationForModuleList = () => {
		history.push(notificationListRoute);
	 };

	 const handleTextChange = (val: string) => {
    const newNotificationModule: CreateNotificationModule = {
      ...notification,
      text: val
    };
    setNotification(newNotificationModule);
  };

	const handleToggleOpenCreateNotificationConfirmModal = () => {
    setOpenCreateNotificationConfirmModal(
      (openCreateNotificationConfirmModal) => !openCreateNotificationConfirmModal
    );
  };

	

	return (<><ContentArea>
			    <Row>
				<Col md={12}>
				<ArrowButton
				backwards
				ariaLabel={t("accessibility.backButton")}
				onClick={() => history.push(notificationListRoute)}
			/>
			<ButtonLink onClick={() => history.push(notificationListRoute)}>
			{t("notification.backToList")}
			</ButtonLink>
				</Col>
		</Row>

		<Row>
				<Col md={12}>
				<Typography
            variant="XLargeBold"
            component={"h1"}
            className={styles.title}
          >
            {t("notification.create")}
          </Typography>
				</Col>
		</Row>

			<Row>
				<Col md={6}>
					<AutocompleteMultipleSelect<CodeBook>
						label={t("notification.modules")}
						name={nameof<typeof notification>("modules")}
						value={notification.modules || []}
						valueProp="id"
						textProp="name"
						onChange={handleModulesChange}
						selectOptions={modules}
						maximumSelectedItems={modules.length}
						minimumInputLength={0}
						required={true}
						errorText={errors?.modules?.toString() || ""}
						/>
				</Col>

				<Col md={6}>
					<TextField
						label= {t("notification.title")}
						value={notification?.title || ""}
						name={nameof<typeof notification>("title")}
						onChange={(e) => handleChange(e, setNotification)} 
						required={true}
						errorText={errors?.title || ""}
					/>
				</Col>
			</Row>

			<Row>
				<Col md={12}>
					<TextEditor
						label= {t("notification.text")}
						initialValue={notification?.text || ""}
						handleChange={handleTextChange} 
						required={true}
						errorText={errors?.text || ""}						
					/>
				</Col>
			</Row>

			<Row>
				<Col md={6}>
					<DatePicker
						value={notification?.dateFrom} 
						label= {t("notification.dateFrom")}
						name={nameof<typeof notification>("dateFrom")}
						onChange={(e) => handleChange(e, setNotification)}
						showTimePicker={true}
						required={true}
						errorText={errors?.dateFrom || ""}
					/>
				</Col>

				<Col md={6}>
					<DatePicker
						value={notification?.dateTo} 
						label= {t("notification.dateTo")}
						name={nameof<typeof notification>("dateTo")}
						onChange={(e) => handleChange(e, setNotification)}
						showTimePicker={true}
					/>
				</Col>
			</Row>
			<Button className={styles.btnMargin} onClick={onCreateClick}>
				{t("common.save")}
			</Button>
		</ContentArea>
		<Modal
			type={"confirm"}
			open={openCreateNotificationConfirmModal}
			title={t("notification.create")}
			confirmText={t("common.approve")}
			cancelText={t("common.quit")}
			onConfirm={handleCreate}
			onClose={handleToggleOpenCreateNotificationConfirmModal}
		>
				<div>
				{t("notification.askToSave")}
				</div>
			</Modal></>
	)

}