import "./App.css";

import { ResponseHandler } from "@king-ict/eupisi-ui/components";

import Layout from "../features/Layout";
import Routing from "../routes/Routing";

function App() {
  require("../variablesImpared.scss");

  return (
    <Layout>
      <ResponseHandler />
      <Routing />
    </Layout>
  );
}
export default App;
