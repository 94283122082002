import { SidebarNavigationContainer, SidebarNavigationItem } from "@king-ict/eupisi-ui/components";
import { useKeycloak } from "@king-ict/eupisi-ui/hooks";
import { useTranslation } from "react-i18next";

import { useAuth } from "../../hooks";
import { RoleEnum } from "../../models/User";
import { aboutProgramRoute, dashboardPublicRoute, dashboardRoute, notificationCreateRoute, notificationEditRoute, notificationListRoute, userCreateRoute, userEditRoute, userListRoute, statementOfAccessibilityRoute } from "../../routes/routes";
import AboutProgram from "../AboutProgram/AboutProgram";
import { Dashboard, DashboardAdmin } from "../Dashboard";
import { NotificationCreate, NotificationEdit, Notifications } from "../NotificationModules";
import StatementOfAccessibility from "../StatementOfAccessibility/StatementOfAccessibility";
import { UserCreate, UserEdit, Users } from "../User";

export interface MatchParams {
  id: string;
}
export interface SidebarProps {
  sidebarIsOpen?: boolean
}

export function Sidebar({ sidebarIsOpen }: SidebarProps) {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const auth = useAuth();

  const canAccessUsers = () => {
    return auth.authUser?.roles?.map(x => x.id).includes(RoleEnum.CAS)
  }; 
  
  
return (
  <SidebarNavigationContainer
    color="primary"
    size="2"
    sidebarIsOpen={sidebarIsOpen}
    sidebarShowHamburger={false}
  >
    <SidebarNavigationItem
      name={t("sidebar.dashboard")}
      route={dashboardRoute()}
      url={dashboardRoute()}
    >      
      <DashboardAdmin />
    </SidebarNavigationItem>
    <SidebarNavigationItem
      name={t("navigation.aboutProgram")}
      route={aboutProgramRoute}
      url={"/aboutprogram"}
      hideFromMenu={true}
    >      
      <AboutProgram />
    </SidebarNavigationItem> 
    <SidebarNavigationItem
      name={t("navigation.statementOfAccessibility")}
      route={statementOfAccessibilityRoute}
      url={"/statement-of-accessibility"}
      hideFromMenu={true}
    >
      <StatementOfAccessibility/>
    </SidebarNavigationItem>

    {keycloak.authenticated && auth.authUser?.roles?.length &&
      <>
        <SidebarNavigationItem
          name={t("sidebar.dashboardPublic")}
          route={dashboardPublicRoute()}
          url={dashboardPublicRoute()}
        >
          <Dashboard />
        </SidebarNavigationItem>        
        <SidebarNavigationItem
          exact={true}
          name={t("notification.sidebarTitle")}
          route={notificationListRoute}
        >
          <Notifications />
        </SidebarNavigationItem>
        <SidebarNavigationItem
          exact={true}
          hideFromMenu={true}
          name={t("common.create")}
          route={notificationCreateRoute}
        >
          <NotificationCreate /> 
        </SidebarNavigationItem>
        <SidebarNavigationItem
          exact={true}
          hideFromMenu={true}
          name={t("common.edit")}
          route={notificationEditRoute}
        >
          <NotificationEdit /> 
        </SidebarNavigationItem>
        {canAccessUsers() && (
          <SidebarNavigationItem
            exact={true}
            name={t("user.sidebarTitle")}
            route={userListRoute}
          >
            <Users />        
          </SidebarNavigationItem>
        )}
        {canAccessUsers() && (
          <SidebarNavigationItem
          exact={true}
          hideFromMenu={true}
          name={t("common.create")}
          route={userCreateRoute}
          >
            <UserCreate />
          </SidebarNavigationItem>
        )}
        {canAccessUsers() && (
          <SidebarNavigationItem
            exact={true}
            hideFromMenu={true}
            name={t("common.edit")}
            route={userEditRoute}
          >
            <UserEdit />
          </SidebarNavigationItem>
        )}
      </>
    }

  </SidebarNavigationContainer>

);  
}