import { Role } from "./Role";

export interface AuthUser {
    id: number;
    oib?: string;
    firstName?: string;
    lastName?: string;
    roles: Role[];
}


export const authUserInitial: AuthUser = {
    id: 0,
    oib: "",
    firstName: "",
    lastName: "",
    roles: [],
  };