import { TFunction } from "i18next";
import * as yup from "yup";

export interface CreateUpdateNotificationValidationSchemeProps {
  t: TFunction;
}

export const CreateUpdateNotificationValidationScheme = ({
  t,
}: CreateUpdateNotificationValidationSchemeProps) => {
  const requiredMsg = t("validations.required");
  const requiredAtLeastOneMsg = t("validations.requiredAtLeastOne");
  const dateFromLessThanDateToMsg = t("validations.dateFromLessThanDateTo");

  return yup.object().shape({
      title: yup
        .string()
        .trim()
        .required(requiredMsg)
        .max(255, t("validations.tooLong", {
          numberOfCharacters: 255,
        })),
      text: yup
        .string()
        .trim()
        .required(requiredMsg),
      dateFrom: yup
        .date()
        .required(requiredMsg)
        .when("dateTo", (dateTo, schema) => {
          if (dateTo) {
            return yup.date().nullable().max(dateTo, dateFromLessThanDateToMsg)
          } else {
            return schema;
          }}),
      modules: yup
        .array()
        .min(1, requiredAtLeastOneMsg),
  });
};
