import React, { useState, useEffect, useContext, createContext } from "react";

import { useKeycloak } from "@king-ict/eupisi-ui/hooks";

import { login as apiLogin, logout as apiLogout } from "../../api/User/UserApi";
import { AuthUser, User, UserPermission } from "../../models/User";

const authContext = createContext<UseProvidedAuthInterface>({} as any);

// =========== PROVIDER ===========

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: { children: any }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// =========== useAuth HOOK ===========

interface UseProvidedAuthInterface {
  isLoading: boolean;
  authenticated: boolean;
  user: User | undefined;
  authUser: AuthUser | undefined;
  currentRole: UserPermission | undefined;
  roles: Array<UserPermission>;

  login: (params?:any) => Promise<any>;
  logout: () => Promise<any>;
  hasPremission: (premissionId: number) => boolean;
}

// Provider hook that creates auth object and handles state
function useProvideAuth(): any {

  const { keycloak } = useKeycloak();

  const [isLoading, setIsLoading] = useState(false);

  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState<User | undefined>();
  const [authUser, setAuthUser] = useState<AuthUser | undefined>();
  const [currentRole, setCurentRole] = useState<UserPermission | undefined>();
  const [roles, setRoles] = useState<Array<UserPermission>>([]);

  useEffect(() => {
    let isAuthenticated = keycloak.authenticated;

    if (!isAuthenticated) {
      // If it is not authenticated clean user data
      setUserData();
      
    } else if (!authenticated) {
      // If is a new login fetch data from API
      checkLogin();
    }

  }, [keycloak]);

  const setUserData = (currentRole?: UserPermission, roles?: Array<UserPermission>) => {
    setUser(currentRole ? currentRole.user : undefined);
    setCurentRole(currentRole);
    setRoles(roles || []);
    setAuthenticated(!!currentRole);
  }

  const login = (params?:any) => {
    if (keycloak.authenticated) {
      checkLogin();
    } else if(params) {
      keycloak.login(params);
    }else {
      keycloak.login();
    }
  };

  const checkLogin = () => {
    setIsLoading(true);

    let ret = new Promise(async (resolve, reject) => {

      try {
        // Login and just get a cookie
        //await apiLogin();

        var auth = await apiLogin();

        setAuthUser(auth);
        // Change role to get user data
        //roleChange().catch(y => resolve(y)).catch(y => reject(y));

      } catch (error) {
        console.log("[useAuth hook] Login ERROR !");
        console.log(error);
        logout();
        setIsLoading(false);
        reject(error);
      }

    });

    return ret;
  };

  const logout = () => {
    setIsLoading(true);

    let ret = new Promise(async (resolve, reject) => {

      await apiLogout();
      keycloak.logout();

      setUserData();

      setIsLoading(true);
      resolve(true);
    });
    return ret;
  };

  const hasPremission = (premissionId: number) => {
    if (!currentRole || !currentRole.rolePermissions) return false;
    return currentRole.rolePermissions.includes(premissionId);
  }

  return {
    isLoading,
    authenticated,
    user,
    authUser,
    currentRole,
    roles,
    login,
    logout,
    hasPremission
  };
}
