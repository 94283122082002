import { useEffect, useState } from "react";

import { Typography } from "@king-ict/eupisi-ui/components";
import { ReactComponent as Ednevnik } from "@king-ict/eupisi-ui/components/Icon//Modules/ednevnik.svg";
import { ReactComponent as ObrazovanjeOdraslih } from "@king-ict/eupisi-ui/components/Icon//Modules/ObrazovanjeOdraslih.svg";
import { ReactComponent as OsnovnaSkola } from "@king-ict/eupisi-ui/components/Icon//Modules/OsnovnaSkola.svg";
import { ReactComponent as Potvrde } from "@king-ict/eupisi-ui/components/Icon//Modules/potvrde.svg";
import { ReactComponent as SRDM } from "@king-ict/eupisi-ui/components/Icon//Modules/SRDM.svg";
import { ReactComponent as SrednjaSkola } from "@king-ict/eupisi-ui/components/Icon//Modules/SrednjaSkola.svg";
import { ReactComponent as UcenickiDomIcon } from "@king-ict/eupisi-ui/components/Icon//Modules/UcenickiDom.svg";
import { ReactComponent as VrticIcon } from "@king-ict/eupisi-ui/components/Icon//Modules/Vrtic.svg";
import { useKeycloak } from "@king-ict/eupisi-ui/hooks";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { getAdminModules } from "../../api/Modules/ModulesAdminApi";
import { ContentArea } from "../../components";
import { IconsEnum } from "../../models/Icons/IconsEnum";
import { ModulesDto } from "../../models/NotificationModules";
import styles from "./DashboardAdmin.module.scss";

export function DashboardAdmin() {
  const { t } = useTranslation();
  const [modules, setModules] = useState<ModulesDto[]>([]);
  const [modulesFetched, setModulesFetched] = useState(false);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    (async () => {
        var res = await getAdminModules();
        setModules(res);
        setModulesFetched(true);
    })();
  }, []);

  const handleGoToLink = (modulLink: string) => {
    window.open((`${modulLink}`), "_self"
    )  
  };
  
  const getIcon = (iconName: string) => {
    switch (iconName) {
      case IconsEnum.Vrtic:
        return <VrticIcon width={50} height={50} className={styles.svg}/>

      case IconsEnum.UcenickiDom:
        return <UcenickiDomIcon width={50} height={50} className={styles.svg}/>
      
      case IconsEnum.SrednjaSkola:
        return <SrednjaSkola width={50} height={50} className={styles.svg}/>

      case IconsEnum.OsnovnaSkola:
        return <OsnovnaSkola width={50} height={50} className={styles.svg}/>    

      case IconsEnum.ObrazovanjeOdraslih:
        return <ObrazovanjeOdraslih width={50} height={50} className={styles.svg}/>

      case IconsEnum.SRDM:
        return <SRDM width={50} height={50} className={styles.svg}/>

      case IconsEnum.eDnevnik:
        return <Ednevnik width={50} height={50} className={styles.svg}/>

      case IconsEnum.Potvrde:
        return <Potvrde width={50} height={50} className={styles.svg}/>
      }
    }  

    return (
      <ContentArea>
        <Container fluid>
          {modulesFetched ? (
            (modules && modules.length > 0 ? (
              <><Row>
                <Col md={12}>
                  <Typography
                    variant="XLargeBold"
                    component={"h1"}
                    className={styles.title}
                  >
                    {t("dashboard.AdminList")}
                  </Typography>
                </Col>
              </Row><Row>
                  {modules.map((modul) => (
                    <Col
                      key={modul.ordinalNumber}
                      sm={5}
                      className={styles.moduleContainer}
                      onClick={!keycloak.authenticated ? ((e) => {
                        e.preventDefault();
                        keycloak?.login({ idpHint: process.env.REACT_APP_KEYCLOAK_IDPHINT, redirectUri: modul.link });
                      }) : (() => handleGoToLink(modul.link))}
                    >
                      <Row>
                        <Col sm={2} className={styles.icons}>
                          {getIcon(modul.icon)}
                        </Col>
                        <Col sm={10}>
                          <Typography variant="largeBold" className={styles.moduleName}>
                            {modul.name}
                          </Typography>
                          <Typography className={[styles.text, styles.moduleText].join(' ')}>
                            {modul.text}
                          </Typography>
                        </Col>
                      </Row>
  
                    </Col>
                  ))}
                </Row></> 
            ) : 
            <Typography variant="baseBold">
              {t("dashboard.emptyList")}
            </Typography>          
            )
          ) : null}
          
            </Container>     
      </ContentArea>
    );
}
