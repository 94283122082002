import { api } from "@king-ict/eupisi-ui/utils";

import { CreateNotificationModule, ModulesAndNotificationsDto, NotificationModuleDeleteDto } from "../../models/NotificationModules";

const apiUrlPrefix: string = "notifications";

export const getModulNotifications = async () => {
  return api.get(
      `${apiUrlPrefix}`
  ) as Promise<ModulesAndNotificationsDto[]>
}

export const getNotification = async (id: number) => {
  return api.get(
    `${apiUrlPrefix}/get/${id}`
  ) as Promise<CreateNotificationModule>
}

export const deleteNotifications = async (
  notificationModleDto: NotificationModuleDeleteDto 
) => {
  return api.delete(
      `${apiUrlPrefix}/notificationModule`,
      notificationModleDto
  ) as Promise<NotificationModuleDeleteDto>
}