import { CodeBook } from "@king-ict/eupisi-ui/models";

export interface CreateNotificationModule {
  id: number,
  modules: CodeBook[];
  title: string;
  text: string;
  dateFrom?: Date;
  dateTo?: Date;
  author: string;
}

export const CreateNotificationModuleInitial: CreateNotificationModule = {
  id: 0,
  modules: [],
  title: "",
  text: "",
  dateFrom: new Date(),
  dateTo: undefined,
  author: ""
};