import "@king-ict/eupisi-ui/styles/theme.scss";
import "@openfonts/source-sans-pro_latin-ext";

import { Suspense } from "react";

import { keycloakInstance } from "@king-ict/eupisi-ui/auth";
import { ReactKeycloakProvider, Spinner } from "@king-ict/eupisi-ui/components";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import "./index.css";
import App from "./app/App";
import store from "./app/store";
import { ProvideAuth } from "./hooks";
import reportWebVitals from "./reportWebVitals";

import "./app/i18n";

ReactDOM.render(
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      LoadingComponent={<Spinner show={true} />}
    >
    <Provider store={store}>
      <ProvideAuth>
        <Router>
          <Suspense fallback={<Spinner show={true} />}>
            <App />
          </Suspense>
        </Router>
      </ProvideAuth>
    </Provider>
  </ReactKeycloakProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
