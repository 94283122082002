import React, { useCallback, useEffect, useState } from "react";

import { Footer, FooterItem } from "@king-ict/eupisi-ui/components";
import Container from "react-bootstrap/Container";
import { useHistory } from "react-router";

import { getUserManual as  getUserManualApi} from "../../api/Documents/DocumentApi";
import { aboutProgramRoute, statementOfAccessibilityRoute } from "../../routes/routes";
import { Navbar } from "../Navbar/Navbar";
import { Sidebar } from "../Sidebar/Sidebar";
import styles from "./Layout.module.scss";
import { Navigation } from "./Navigation";


interface Props {
  children?: React.ReactNode;
}

export default function Layout({ children }: Props) {
  const history = useHistory();
  const [showSidebar, setShowSidebar] = useState<boolean>(true);

  const queryString = new URLSearchParams(window.location.search);

  const person = queryString.get('person');
  const forLegalIps = queryString.get('forLegalIps');
  const forLegalSource = queryString.get('forLegalSource');
  const toLegalIps = queryString.get('toLegalIps');
  const toLegalSource = queryString.get('toLegalSource');

  useEffect(() => {
    let fn = () => {
        if (window.innerWidth <= 768) {
            setShowSidebar(false);
          } else {
            setShowSidebar(true);
          }
    };

    fn();

    window.addEventListener("resize", fn);

    return () => window.removeEventListener("resize", fn);
}, []);

  useEffect(() => {
    (async () => {
      if (person || forLegalIps || toLegalIps) {
        saveCookie('person', person || "");
        saveCookie('forLegalIps', forLegalIps || "");
        saveCookie('forLegalSource', forLegalSource || "");
        saveCookie('toLegalIps', toLegalIps || "");
        saveCookie('toLegalSource', toLegalSource || "");
        clearCookie('authDataId', document.domain === 'localhost' ? 'localhost' : '.e-upisi.hr');
      }
    })();
  }, [queryString]);

  const saveCookie = (
    cookieName: string,
    cookieValue: string
  ) => {
    const cookieDomain = process.env.REACT_APP_AUTH_COOKIE_DOMAIN;

    let cookieString = `${cookieName}=${cookieValue};domain=${cookieDomain};path=/;`;

    if (process.env.NODE_ENV === 'development'){
      cookieString += 'SameSite=None;Secure;'
    }

    document.cookie = cookieString;
  };

  function clearCookie(name: string, domain: string) {
    const path = "/";
    const date = "Thu, 01 Jan 1970 00:00:00 GMT";
    document.cookie = name + "=; expires=" + +date + "; domain=" + domain + "; path=" + path;
}; 

const setImpairedStyle = useCallback(() => {
  document.documentElement.classList.add("theme-vision-impaired");
  document.documentElement.classList.remove("theme-default");
}, []);

const removeImpairedStyle = useCallback(() => {
  document.documentElement.classList.add("theme-default");
  document.documentElement.classList.remove("theme-vision-impaired");
}, []);

const showAboutProgram = () => history.push(aboutProgramRoute);
const showIzjavaOPristupacnosti = () => { 
  window.scrollTo(0, 0);
  history.push(statementOfAccessibilityRoute);
}

const footerItems: Array<FooterItem> = [
  {
    label: "Korisničke upute",
    callback: (item: FooterItem) => {
      getUserManualApi();
      return false;
    },
  },
  {
    label: "O programu",
    callback: (item: FooterItem) => {
      showAboutProgram();
      return false;
    },
  },
  {
    label: "Izjava o pristupačnosti",
    callback: (item: FooterItem) => {
      showIzjavaOPristupacnosti();
      return false;
    },
  },
];

  return (
    <>
      <Navbar
        setImpairedStyle={setImpairedStyle}
        removeImpairedStyle={removeImpairedStyle}
      />
      <Navigation onIconClick={() => setShowSidebar(!showSidebar)} />
      <main className={styles.main}>
        <Container fluid className={styles.main}>
          <Sidebar sidebarIsOpen={showSidebar} />
          {children}   
        </Container>     
      </main>     
      <Footer
        items={footerItems}
      />
    </>
  );
}
