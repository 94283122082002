import { api } from "@king-ict/eupisi-ui/utils";

import { CreateNotificationModule } from "../../models/NotificationModules";

const apiUrlPrefix: string = "notifications";

export const createNotificationModule = async (data: CreateNotificationModule) => {
  return api.post(
      `${apiUrlPrefix}/notificationModule`,
      data
  ) as Promise<CreateNotificationModule>
}


export const editNotificationModule = async (data: CreateNotificationModule) => {
  return api.put(
      `${apiUrlPrefix}/notificationModule`,
      data
  ) as Promise<CreateNotificationModule>
}