import { api } from "@king-ict/eupisi-ui/utils";

import { OibIdentification, OibServiceResult } from "../../models";
import { User, Role, AuthUser } from "../../models/User";

const apiUrlPrefix: string = "users";

export const getUserSession = async () => {
    return api.get(`${apiUrlPrefix}/session`) as Promise<User>;
  };

  export const getUsers = async () => {
    return api.get(`${apiUrlPrefix}`) as Promise<AuthUser[]>;
  };

export const getUser = async (id: number) => {
    return api.get(
        `${apiUrlPrefix}/get/${id}`
    ) as Promise<AuthUser>
}

export const createUser = async (user: AuthUser) => {
    return api.post(
        `${apiUrlPrefix}/create`,
        user
    ) as Promise<AuthUser>
}

export const editUser = async (user: AuthUser) => {
    return api.put(
        `${apiUrlPrefix}/edit`,
        user
    ) as Promise<AuthUser>
  }

export const deleteUser = async (
    id: number 
  ) => {
    return api.delete(
        `${apiUrlPrefix}/delete/${id}`
    ) as Promise<void>
  }


export const getRoles = async () => {
    return api.get(
        `${apiUrlPrefix}/get-roles`
    ) as Promise<Role[]>
}

export const getUserOibServiceData = async (oib: string) => {
    return api.get(
        `${apiUrlPrefix}/oib/${oib}`
    ) as Promise<OibServiceResult<OibIdentification>>
}

export const login = async () => {
    return api.get(
        `${apiUrlPrefix}/login`
    ) as Promise<AuthUser>
}

export const logout = async () => {
    return api.get(
        `${apiUrlPrefix}/logout`
    ) as Promise<void>
}