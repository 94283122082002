import { useEffect, useState } from "react";

import { ArrowButton, AutocompleteMultipleSelect, ButtonLink, Modal, TextField, Typography } from "@king-ict/eupisi-ui/components"
import { useInput, useValidation } from "@king-ict/eupisi-ui/hooks";
import { nameof } from "@king-ict/eupisi-ui/utils";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { createUser, getRoles as getRolesApi } from "../../api/User/UserApi";
import { ContentArea } from "../../components";
import { AuthUser, authUserInitial, CreateUpdateUserValidationScheme, Role } from "../../models/User";
import {  userListRoute } from "../../routes/routes";
import styles from "./Users.module.scss";

export function UserCreate() {

	const { t } = useTranslation();
	const history = useHistory();

	//const { id } = useParams<{ id: string }>();

	const { handleChange } = useInput();

	const[roles, setRoles] = useState<Role[]>([]);

	const [user, setUser] = useState<AuthUser>(authUserInitial);

	const [openCreateUserConfirmModal, setOpenCreateUserConfirmModal] = useState(false);

	//const type = id ? "update" : "create";

	const [errors, doValidations] = useValidation({
    data: user,
    validationSchema: CreateUpdateUserValidationScheme,
  });


	useEffect(() => {
		(async () => {
			var result = await getRolesApi()
			setRoles(result);
		})();
	 }, []);
	 
	 const handleRoleChange = (e: any) => {
		const newRoleList = e.target.value as Role[];
		const newUser: AuthUser = {
			...user,
			roles: newRoleList
		}
		setUser(newUser);
	  };

		const onCreateClick = async () => {
			const isValid = doValidations();
			if (isValid) {
				setUser(user);
				handleToggleOpenCreateUserConfirmModal();
			}           
    };

		const handleCreate = async () => {
      await createUser(user)
			.then(handleToggleOpenCreateUserConfirmModal)
			.then(goToUserList);
      };

	  const goToUserList = () => {
		history.push(userListRoute);
	 };


	const handleToggleOpenCreateUserConfirmModal = () => {
    setOpenCreateUserConfirmModal(
      (openCreateUserConfirmModal) => !openCreateUserConfirmModal
    );
  };
	

	return (<><ContentArea>
		<Container fluid>
		<Row>
				<Col md={12}>
				<ArrowButton
				backwards
				ariaLabel={t("accessibility.backButton")}
				onClick={() => history.push(userListRoute)}
			/>
			<ButtonLink onClick={() => history.push(userListRoute)}>
			{t("user.backToList")}
			</ButtonLink>
				</Col>
		</Row>

		<Row>
				<Col md={12}>
				<Typography
            variant="XLargeBold"
            component={"h1"}
            className={styles.title}
          >
            {t("user.create")}
          </Typography>
				</Col>
		</Row>
					
			<Row>
				<Col md={6}>
					<TextField
						label={t("user.firstName")}
						name={nameof<typeof user>("firstName")}
						onChange={(e) => handleChange(e, setUser)}
						value={user?.firstName || ""}
						required={true}
						errorText={errors?.firstName || ""}
						
						/>
				</Col>

				<Col md={6}>
					<TextField
						label={t("user.lastName")}
						name={nameof<typeof user>("lastName")}
						onChange={(e) => handleChange(e, setUser)}
						value={user?.lastName || ""}
						required={true}
						errorText={errors?.lastName || ""} 
						/>
				</Col>
			</Row>

			<Row>
				<Col md={6}>
					<AutocompleteMultipleSelect<Role>
						label={t("user.roles")}
						name={nameof<typeof user>("roles")}
						value={user.roles || []}
						valueProp="id"
						textProp="name"
						onChange={handleRoleChange}
						selectOptions={roles}
						maximumSelectedItems={roles.length}
						minimumInputLength={0}
						required={true}
						errorText={errors?.roles?.toString()}
						/>
				</Col>

				<Col md={6}>
					<TextField
						label={t("user.oib")}
						name={nameof<typeof user>("oib")}
						onChange={(e) => handleChange(e, setUser)}
						value={user?.oib || ""} 
						required={true}
						errorText={errors?.oib || ""}
						/>
				</Col>
			</Row>

			<Button className={styles.btnMargin} onClick={onCreateClick}>
				{t("common.save")}
			</Button>
			</Container>
		</ContentArea>
		<Modal
			type={"confirm"}
			open={openCreateUserConfirmModal}
			title={t("user.create")}
			confirmText={t("common.approve")}
			cancelText={t("common.quit")}
			onConfirm={handleCreate}
			onClose={handleToggleOpenCreateUserConfirmModal}
		>
				<div>
				{t("user.askToSave")}
				</div>
			</Modal></>
	)

}